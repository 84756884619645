<template lang="pug">
.om-tactic-recommendation(
  data-track-property="eventName:admin_click|location:wizard-recommendation|setting:{event}"
)
  wizard-loader(v-if="loader" @loaderComplete="handleLoaderComplete")
  template(v-else)
    Recommendation(
      :recommendedEasyTactics="recommendedEasyTactics"
      :selectedTheme="selectedTheme"
      :customTheme="customTheme"
      :tacticsLoading="tacticsLoading"
      :customThemeLoading="customThemeLoading"
      @showSimilarTemplates="showSimilarTemplates"
      @showTemplatePreview="showTemplatePreview"
      :shownLimit="8"
    )

  Modals
</template>
<script>
  import { mapGetters } from 'vuex';
  import GET_CUSTOM_THEMES from '@/graphql/GetCustomThemes.gql';
  import GET_RECOMMENDED_USE_CASES_V2 from '@/graphql/GetRecommendedUseCasesV2.gql';

  import WizardLoader from '@/components/Wizard/WizardLoader.vue';
  import { lsStore } from '@/helpers/localStorage';

  import paletteMixin from '@/mixins/palette';
  import ssrMixin from '@/mixins/ssr';

  import { WIZARD_SETUP_KEY } from '@/config/constants';
  import { multiColorThemes } from '../consts';

  import sharedMixin from '../shared';
  import smartWizardMixin from '../smartWizard';
  import { SIMILAR_TEMPLATES } from '../flow';

  export default {
    components: {
      Recommendation: () => import('@/components/Tactics/Recommendation.vue'),
      Modals: () => import('@/components/Tactics/Modals.vue'),
      WizardLoader,
    },
    mixins: [paletteMixin, ssrMixin, sharedMixin, smartWizardMixin],

    data() {
      return {
        loader: false,
        tacticsLoading: true,
        recommendedEasyTactics: [],
        selectedColor: '',
        theme: 'Clean',
        customThemeLoading: true,
        customTheme: undefined,
      };
    },

    computed: {
      ...mapGetters(['wizardPreferences']),
      themes() {
        return multiColorThemes.map((t) => ({
          key: t,
          value: t,
        }));
      },

      style() {
        return this.wizardPreferences ? this.wizardPreferences.style || null : null;
      },

      selectedTheme() {
        return this.$route.query.theme || this.style;
      },

      themeKit() {
        return this.customTheme?.themeKit;
      },
    },

    watch: {
      async selectedTheme(v) {
        await this.updateWizardPreferences({ style: v });
      },
      loader() {
        this.$nextTick(() => {
          this.updateDimensions();
        });
      },
    },

    created() {
      lsStore(WIZARD_SETUP_KEY, '1', false);
      this.loader = !window.__skip_wizard_recommendation_loader;
      if (!this.loader) {
        this.$emit('loaderAnimationEnd');
      }
      delete window.__skip_wizard_recommendation_loader;
      this.initiate();
    },

    methods: {
      async initiate() {
        this.recommendedEasyTactics = await this.fetchRecommendedUseCases();
        this.selectedColor = this.$route.query.color;
        this.updatePalette(this.selectedColor);
        const inputs = this.getWizardPreferences();
        await Promise.all([
          this.updateWizardPreferences({
            ...inputs,
            style: this.selectedTheme,
          }),
          this.setThemeKit(),
        ]);
      },
      async setThemeKit() {
        this.customThemeLoading = true;
        const {
          data: { themes },
        } = await this.$apollo.query({
          query: GET_CUSTOM_THEMES,
        });
        this.customTheme = themes?.custom?.find(({ _id }) => _id === this.$route.query.customTheme);
        this.customThemeLoading = false;
      },
      handleLoaderComplete() {
        this.loader = false;
        this.$emit('loaderAnimationEnd');
      },
      async fetchRecommendedUseCases() {
        this.tacticsLoading = true;
        const params = this.buildSmartWizardQuery();
        params.style = this.selectedTheme;
        params.customTheme = true;
        const {
          data: { useCases },
        } = await this.$apollo.query({
          query: GET_RECOMMENDED_USE_CASES_V2,
          variables: {
            style: this.selectedTheme,
            limit: 12,
          },
        });
        this.tacticsLoading = false;
        return useCases;
      },
      async showTemplatePreview({ useCase, template }) {
        await this.updateWizardPreferences({ useCase: useCase._id });
        const { color, coupon, goal, list } = this.$route.query;
        this.$modal.show('template-preview', {
          templateId: template._id,
          color: this.themeKit ? undefined : color,
          themeKit: this.themeKit ? this.themeKit : undefined,
          isWizard: true,
          heapTracking: {
            name: 'template-recommendation-selection-wizard',
            data: {
              useCaseName: useCase.name.en,
              color,
              coupon,
              goal,
              list,
              theme: template.theme,
            },
          },
        });
      },
      async showSimilarTemplates({ useCase }) {
        await this.updateWizardPreferences({ useCase: useCase._id });
        this.$router.push(this.getSimilarTemplatesRoute({ useCase }));
      },
      getSimilarTemplatesRoute({ useCase }) {
        return {
          name: this.$route.name,
          params: { step: SIMILAR_TEMPLATES },
          query: {
            id: useCase._id,
            color: this.$route.query.color,
            buildList: this.$route.query.buildList,
            businessType: this.$route.query.businessType,
            contentMarketing: this.$route.query.contentMarketing,
            discounts: this.$route.query.discounts,
            recurringCustomers: this.$route.query.recurringCustomers,
            seasonalSales: this.$route.query.seasonalSales,
            theme: this.$route.query.theme,
            customTheme: this.$route.query.customTheme,
          },
        };
      },
    },
  };
</script>

<style lang="sass">

  @media (max-width: 992px)
    .container
      max-width: 93vw

  .om-tactic-recommendation
    padding-bottom: 3.5rem

    &-style-chooser
      position: sticky
      top: 24px
      padding: 12px 0 24px
      border-radius: 8px
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1), 0 4px 16px rgba(0, 0, 0, 0.04)
      margin-right: 12px

      &-title
        font-size: 18px
        line-height: 28px
        font-weight: 500
        color: #8F97A4
        padding: 12px

  .om-tactic-more-advanced-tactics
    margin: auto
    background: #FFEFE5
    padding: 15px
    position: relative
    overflow: hidden

    .text-line-1
      font-weight: 700
      font-size: 1.5rem

    .text-line-2
      font-weight: 700
      font-size: 1rem

  .om-tactic-more-tactics-bg-graph
    position: absolute
    height: 100%
    width: auto
    right: min(20rem, 21%)
</style>
