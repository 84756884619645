<template lang="pug">
div
  smart-wizard-page(:heading="$t('onboarding.wizard.pages.buildList.title')")
    template(#lead)
      p {{ $t('onboarding.wizard.pages.buildList.description') }}
    template(#actions)
      om-select-cards(v-model="option" :options="options" vertical)
</template>
<script>
  import navigationMixin from '../navigation';
  import smartWizardMixin from '../smartWizard';

  export default {
    mixins: [smartWizardMixin, navigationMixin],
    computed: {
      options() {
        const opts = ['email', 'sms', 'emailAndSms'];
        return opts.map((value) => ({ value, key: this.getTranslation(value) }));
      },
    },
    methods: {
      getTranslation(value) {
        return this.$t(`onboarding.wizard.pages.buildList.options.${value}`);
      },
    },
  };
</script>
