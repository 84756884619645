<template lang="pug">
.om-wizard-brandkit.pb-6.mb-6
  .text-center
    wizard-title.mb-3 {{ $t('onboarding.customTheme.customize.title') }}
    om-body-text(bt400md) {{ $t('onboarding.customTheme.customize.description') }}
  .row(:style="'margin-top: 1.5rem'")
    .col-9
      .wizard-theme-item
        .wizard-theme-head
          .dot(v-for="dot in 3")
        .wizard-theme-content
          transition(name="fade")
            template-frame(
              @observable="addObservable($event.$el)"
              v-if="selectedMainTemplate"
              @inited="updateDimensions"
              :dimensions="boxDimensions"
              allowSsr
              :template="selectedMainTemplate"
              @contentLoaded="onContentLoaded"
              :endpoint="'themekit-preview'"
              :payload="{ themeKit: payload, baseTheme: true }"
              clearOnChange
            )
    .col-3
      om-heading.text-left.mb-2(h6) {{ $t('onboarding.customTheme.customize.labels.name') }}
      om-input#brandkit-name.mb-4(v-model="name" :error="$v.name.$error")
      template(v-if="currentLogo")
        om-heading.text-left.mb-2(h6) {{ $t('onboarding.customTheme.customize.labels.logo') }}
        #brandkit-logo.mb-4.d-flex.align-items-center
          .brandkit-logo-preview-box.d-flex.align-items-center.justify-content-center.flex-grow-1.mr-2.cursor-pointer(
            @click="showImageManager"
          )
            img(:src="currentLogo")
          om-button(
            v-if="isOriginalLogo"
            small
            iconOnly
            ghost
            icon="upload"
            @click="showImageManager"
          )
          om-button(v-else small iconOnly ghost icon="trash-alt" @click="clearLogo")
      om-heading.text-left.mb-2(h6) {{ $t('onboarding.customTheme.customize.labels.color') }}
      brand-kit-palette.mb-3(
        v-if="mainColor"
        ref="brandKitPalette"
        :color="mainColor"
        :themeColors="themeColors"
        @mainColor="mainColor = $event"
        @secondaryColors="themeColors = $event"
        @backdropVisbility="onBackdropVisibilityChange"
      )
      om-heading.text-left.mb-2(h6) {{ $t('onboarding.customTheme.customize.labels.font') }}
      brand-font.mb-4(ref="primaryFont" :value="selectedFont" @select="selectFont($event)")
      om-heading.text-left.mb-2(h6) {{ $t('onboarding.customTheme.customize.labels.secondaryFont') }}
      brand-font.mb-4(
        ref="secondaryFont"
        :value="selectedSecondaryFont"
        @select="selectSecondaryFont($event)"
      )
      om-heading.text-left.mb-2(h6) {{ $t('onboarding.customTheme.customize.labels.corners') }}
      brand-kit-radius-elements.brand-kit-radiuses(
        @select="selectRadiusType($event)"
        :value="selectedRadius ? `${selectedRadius}-type` : null"
      )
      om-button(
        secondary
        data-track="wizard-brandKit-resetTheme"
        block
        @click="reset()"
        :style="'margin-top: 1rem'"
      ) {{ $t('onboarding.customTheme.customize.buttons.reset') }}
  custom-font-upload-v2
  transition(name="fade")
    UserImages(@use="onUseImage" @upload="$apollo.queries.images.refetch()")
  .om-color-picker-backdrop(v-if="showBackdrop" @click.stop="onBackdropClick")
</template>
<script>
  import { get } from 'lodash-es';
  import { mapMutations, mapGetters, mapState } from 'vuex';
  import CustomFontUploadV2 from '@/editor/components/modals/CustomFontUploadV2.vue';
  import TemplateFrame from '@/components/Template/TemplateFrame.vue';
  import BrandKitRadiusElements from '@/editor/components/sidebar/RadiusElements.vue';
  import BrandFont from '@/components/Wizard/BrandFont.vue';
  import BrandKitPalette from '@/components/Wizard/BrandKitPalette.vue';
  import previewParentMixin from '@/mixins/previewParent';
  import ssrMixin from '@/mixins/ssr';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import { brandkitTracker } from '@/services/userInteractionTracker/tracker';
  import { getPaletteColors } from '@om/template-properties/src/getPaletteColors';
  import { getNameFromURL } from '@om/template-properties/src/imageReplace';
  import UPSERT_CUSTOM_THEME from '@/graphql/UpsertCustomTheme.gql';
  import GET_CUSTOM_THEMES from '@/graphql/GetCustomThemes.gql';
  import { MODAL_NAME } from '@/components/Wizard/UserImages.vue';
  import logoMixin from '@/mixins/logo';
  import sharedMixin from '../shared';
  import navigationMixin from '../navigation';

  const THEME_NAME_FALLBACK = 'My theme 1';

  export default {
    components: {
      TemplateFrame,
      BrandKitRadiusElements,
      BrandFont,
      BrandKitPalette,
      CustomFontUploadV2,
      UserImages: () => import('@/components/Wizard/UserImages.vue'),
    },
    mixins: [
      sharedMixin,
      navigationMixin,
      previewParentMixin,
      ssrMixin,
      observableCollectionMixin,
      logoMixin,
    ],
    apollo: {
      customThemes: {
        query: GET_CUSTOM_THEMES,
        variables: {
          resolveMainTemplate: true,
        },
        update({ themes }) {
          const baseThemeId = this.$route.query?.baseThemeId;
          const firstThemeSourceId = themes?.custom?.[0]?.sourceTheme?._id;
          const firstThemeId = themes?.custom?.[0]?._id;
          if (firstThemeId && baseThemeId === firstThemeSourceId) {
            this.addThemeKitIdToUrl(firstThemeId).then(() => {
              this.initiateValues(true);
            });
          }
          return themes;
        },
      },
    },
    data() {
      return {
        mainColor: null,
        themeColors: null,
        selectedRadius: null,
        selectedFont: null,
        selectedSecondaryFont: null,
        showBackdrop: false,
        loaded: false,
        name: THEME_NAME_FALLBACK,
        loadFontsFromAccount: null,
        imageModalName: MODAL_NAME,
        images: [],
        allTemplateImages: [],
        image: null,
        logo: null,
      };
    },
    computed: {
      ...mapState(['account']),
      ...mapGetters(['getLocale', 'wizardPreferences']),
      lottieLoading() {
        return `wizard_templates_loading_${this.getLocale}`;
      },
      color() {
        const params = new URLSearchParams(window.location.search);
        return this.mainColor || params.get('color');
      },
      theme() {
        return this.$route.query.theme;
      },
      needRecommendationInfo() {
        const { useCases } = this.wizardPreferences;
        return !useCases.length;
      },
      selectedMainTemplate() {
        const theme = this.customThemes?.base?.find?.(({ name }) => name === this.theme);
        return theme?.mainTemplate;
      },
      brandKit() {
        return {
          mainColor: this.mainColor,
          themeColors: this.themeColors,
          font: this.brandKitFont,
          radius: this.selectedRadius,
        };
      },
      templateThemeColors() {
        return ['Clean', 'Photographic'].includes(this.theme)
          ? get(this.selectedMainTemplate, 'template.style.palette.themeColors', [])
          : [];
      },
      brandKitFont() {
        if (!this.selectedFont) return null;
        const { key, value, name } = this.selectedFont;
        return { value: key, name: value || name };
      },
      customThemeId() {
        return this.$route.query.customTheme;
      },
      baseThemeId() {
        return this.$route.query.baseThemeId;
      },
      selectedCustomTheme() {
        return (
          this.customThemes?.custom.find(({ _id }) => _id === this.customThemeId) ||
          this.customThemes?.base.find(({ _id }) => _id === this.baseThemeId)
        );
      },
      businessName() {
        const name = (this.account.businessName || '').trim();
        return name?.length ? this.$t('onboarding.customTheme.themeName', { name }) : null;
      },
      payload() {
        const themeKit = this.buildThemeKit();
        return {
          ...themeKit,
          name: undefined,
          image: this.usedImage,
        };
      },
      isOriginalLogo() {
        return this.logo === this.selectedCustomTheme?.logo?.original;
      },
      usedImage() {
        return (
          this.images.find(this.imageNameComparer) ??
          this.allTemplateImages.find(this.imageNameComparer)
        );
      },
      currentLogo() {
        return this.usedImage?.url;
      },
    },
    validations: {
      name: {
        required: true,
      },
    },
    watch: {
      selectedMainTemplate(selected) {
        if (!selected) return;
        this.initiateValues();
        this.$nextTick(() => {
          this.updateDimensions();
        });
      },
      loaded() {
        this.$emit('loaded', this.loaded);
      },
    },
    created() {
      this.$emit('loaded', this.loaded);
      // preload loading animation
      import(`@/lottie/${this.lottieLoading}.json`);
      document.addEventListener('click', () => {
        this.hideColorPicker();
      });
    },
    methods: {
      ...mapMutations(['hideColorPicker']),
      selectRadiusType(element) {
        const radius = element.type.replace('-type', '');
        this.selectedRadius = radius;
        brandkitTracker.trackChange('wizardRound', { value: radius });
      },
      selectFont(font) {
        this.selectedFont = font;
        this.$refs?.secondaryFont?.add?.(font);
        brandkitTracker.trackChange('wizardFont', { value: font });
      },
      selectSecondaryFont(font) {
        this.selectedSecondaryFont = font;
        this.$refs?.primaryFont?.add?.(font);
        brandkitTracker.trackChange('brandkitSecondary', { value: font });
      },
      reset() {
        this.initiateValues(true);
      },
      async nextPage() {
        this.$v.$touch();
        if (this.$v.$error) {
          return;
        }
        try {
          await this.createNewTheme();
          await this.updateMainColorInUrl(this.mainColor);
          this.next(null, { color: this.mainColor });
        } catch (error) {
          console.error('unable to save brand kit: ', error);
        }
      },
      buildThemeKit() {
        const fonts = [this.selectedFont?.key, this.selectedSecondaryFont?.key || 'open-sans'];
        return {
          fonts,
          rounding: this.selectedRadius || null,
          name: this.name,
          colors: {
            mainColor: this.mainColor,
            secondaryColors: getPaletteColors(this.mainColor).slice(1),
            themeColors: this.themeColors,
          },
        };
      },
      async createNewTheme() {
        const themeKit = this.buildThemeKit();
        try {
          const {
            data: { result },
          } = await this.$apollo.mutate({
            mutation: UPSERT_CUSTOM_THEME,
            variables: {
              id: this.customThemeId,
              name: this.name,
              sourceThemeName: this.theme,
              themeKit,
              hidden: false,
              logo: this.usedImage,
            },
          });
          if (result) {
            await this.addThemeKitIdToUrl(result._id);
          }
        } catch (e) {
          console.error('Cannot save custom theme');
          this.$notify({
            type: 'error',
            message: this.$t('notifications.saveError'),
          });
        }
      },
      async updateMainColorInUrl(mainColor) {
        const params = new URLSearchParams(window.location.search);
        const decodedColor = decodeURIComponent(params.get('color'));
        if (!mainColor && decodedColor !== mainColor) {
          this.setQueryStringParameter('color', decodedColor);
        }
      },
      async addThemeKitIdToUrl(customTheme) {
        if (this.customThemeId !== customTheme) {
          this.setQueryStringParameter('customTheme', customTheme);
        }
      },
      setQueryStringParameter(name, value) {
        const params = new URLSearchParams(window.location.search);
        params.set(name, value);
        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
      },
      onBackdropVisibilityChange(show) {
        this.showBackdrop = show;
      },
      onBackdropClick() {
        this.$refs.brandKitPalette.hideColorPicker();
      },
      onPaletteChange(colors) {
        const [main, ...secondaries] = colors;
        this.mainColor = main;
        this.themeColor = secondaries;
      },
      setThemeColors() {
        const { themeColors = [] } = this.selectedCustomTheme?.themeKit?.colors;
        this.themeColors = JSON.parse(JSON.stringify(themeColors));
      },
      setMainColor() {
        const params = new URLSearchParams(window.location.search);
        const decodedColor = decodeURIComponent(params.get('color'));
        if (!this.mainColor && decodedColor) {
          this.mainColor = decodedColor;
        }
      },
      setSelectedFont() {
        const [primary = 'open-sans', secondary = 'open-sans'] =
          this.selectedCustomTheme?.themeKit.fonts || [];
        this.selectedFont = { key: primary };
        this.selectedSecondaryFont = { key: secondary };
      },
      setSelectedRadius() {
        const { rounding = 'none' } = this.selectedCustomTheme?.themeKit || {};
        this.selectedRadius = rounding;
      },
      setSelectedLogo(reset) {
        const { original, current } = this.selectedCustomTheme?.logo || {};
        this.logo = reset ? original : current ?? original;
        if (!this.isOriginalLogo) {
          this.image = this.usedImage;
        }
      },
      setCustomThemeName() {
        const themeName = this.customThemeId
          ? this.selectedCustomTheme.name
          : this.businessName || THEME_NAME_FALLBACK;
        this.name = themeName;
      },
      initiateValues(reset) {
        this.setCustomThemeName(reset);
        this.setMainColor(reset);
        this.setThemeColors(reset);
        this.setSelectedFont(reset);
        this.setSelectedRadius(reset);
        this.setSelectedLogo(reset);
        this.loaded = true;
      },
      showImageManager() {
        this.$modal.show(this.imageModalName);
      },
      onUseImage(image) {
        this.image = image;
        this.logo = this.getNameFromURL(image?.url);
      },
      clearLogo() {
        this.image = null;
        this.setSelectedLogo(true);
      },
      imageNameComparer({ url }) {
        const pureName = getNameFromURL(url)
        const pureLogo = getNameFromURL(this.logo)

        return pureName && pureLogo && pureName === pureLogo
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/pages/_wizard.sass'
  @import "@/sass/variables/_colors.sass"
  .wizard-theme
    &-item
      height: 100%
    &-content
      width: 100%
      .template-thumbnail-box
        border-top-left-radius: 0
        border-top-right-radius: 0
        height: 100%
  .brand-kit-radiuses
    justify-content: space-between
    .radius-elements
      flex: 0 0 calc(25% - 8px)
      margin: 0
  .wizard-radius-container
    justify-content: space-between
    margin: 0 -4px
    .radius-elements
      margin: 0 4px
  .om-color-picker-backdrop
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 1100
  #brandkit-logo
    .btn
      height: max-content
  .brandkit-logo-preview-box
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMElEQVQ4T2N89uzZfwY8QFJSEp80A+OoAcMiDP7//483HTx//hx/Ohg1gIFx6IcBALl+VXknOCvFAAAAAElFTkSuQmCC")
    max-width: 15rem
    height: 3.75rem
    border: 1px solid $om-gray-300
    border-radius: 4px
    padding: .25rem
    img
      width: 100%
      height: auto
      max-height: 3.25rem
      object-fit: contain
  .om-wizard
    .brand-modal-body
      .user-images-body
        .om-progressive-image
          height: 100%
</style>
