<template lang="pug">
div
  smart-wizard-page(:heading="$t('onboarding.wizard.pages.discounts.title')")
    template(#lead)
      p {{ $t('onboarding.wizard.pages.discounts.description') }}
    template(#actions)
      om-select-cards(v-model="option" :options="options" vertical)
</template>
<script>
  import navigationMixin from '../navigation';
  import smartWizardMixin from '../smartWizard';

  export default {
    mixins: [navigationMixin, smartWizardMixin],
    computed: {
      options() {
        return [
          {
            value: 'yes',
            key: this.$t('yes'),
          },
          {
            value: 'no',
            key: this.$t('no'),
          },
        ];
      },
    },
  };
</script>
