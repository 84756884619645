<template lang="pug">
div
  smart-wizard-page(
    :beforeHeading="$t('onboarding.wizard.pages.goals.greeting')"
    :heading="$t('onboarding.wizard.pages.goals.title')"
  )
    template(#actions)
      om-select-cards.mt-6(v-model="selected" :options="options" vertical multiple)
    template(#navigation-buttons)
      span
</template>
<script>
  import { track } from '@/services/xray';
  import navigationMixin from '../navigation';
  import smartWizardMixin from '../smartWizard';

  export default {
    mixins: [smartWizardMixin, navigationMixin],
    data() {
      return {
        selected: [],
      };
    },
    computed: {
      options() {
        return Object.keys(this.$t(`tactic.goal`)).map((k) => ({
          value: k,
          key: this.$t(`tactic.goal.${k}`),
        }));
      },
    },
    methods: {
      async nextPage() {
        await this.updateWizardPreferences({ goals: this.selected });
        track('wizard-goals', { selected: this.selected });
        await this.next();
      },
    },
  };
</script>
